/* eslint-disable */
//import './helpers/__global';

// import { config as envConfig } from 'dotenv';
// envConfig();

/**
 * ⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️
 * 🏹 See ./pages/MainPage.tsx for main app component!
 * ⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️
 *
 * This file loads react.
 * You don't need to change this file!!
 */

/**
 * Loads {@see App} which sets up the application async.
 * The main page is in the component {@see MainPage}
 */
const run = async (): Promise<void> => {
  await import('./helpers/__global');
  // dynamic imports for code splitting
  const { lazy, Suspense, StrictMode } = await import('react');
  const { createRoot } = await import('react-dom/client');
  const App = lazy(() => import('./App'));

  // while developing, you can set this to true to see the maintenance page
  const isMaintenance: boolean = import.meta.env.VITE_MAINTENANCE_MODE === 'true';

  const container = document.getElementById('root');
  const reactRoot = createRoot(container!);
  reactRoot.render(
    // <StrictMode>
    <Suspense fallback={<div />}>{isMaintenance ? <div>Under maintenance</div> : <App />} </Suspense>
    // </StrictMode>
  );
};

void run();

export {};
